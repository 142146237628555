import Swiper from 'swiper';
import { Navigation } from 'swiper';
Swiper.use(Navigation);

const slider = document.querySelector('.swiper');
const slides = document.querySelectorAll('.swiper-slide');
if (slides && slider) {
    const options = {
        speed: 650,
        navigation: {
            nextEl: '.navi__right',
            prevEl: '.navi__left',
          },
      }

    const swiperInstance = new Swiper(slider, options);
    const swiper = slider.swiper;  
}

function vh() {
    let vh = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

vh();
window.addEventListener('resize', vh);